<template>
    <v-card
        class="teaser-card"
        elevation="12"
      >
        <v-card-title class="justify-center">
            Nuestro Camino al "Sí, Acepto"
        </v-card-title>
        <v-card-text class="text-center">
          <div class="video-placeholder d-flex justify-center align-center">
            <v-btn
              icon
              color="white"
              elevation="0"
              @click="handlePlay"
            >
              <v-icon size="48">mdi-play-circle</v-icon>
            </v-btn>
          </div>
          <p class="release-date">✨ Estreno: 28 de diciembre - 2024 ✨</p>
        </v-card-text>
      </v-card>
  </template>
  
  <script lang="ts">
  import { defineComponent } from "vue";
  import AlertModal from '@/helpers/AlertModal';
  import { TypeAlertModal } from '@/enums/alertModal.enum';   
  export default defineComponent({
    name: "TeaserCard",
    methods: {
      handlePlay() {
        // alert("Fecha de estreno: 28 de diciembre - 2024")
        AlertModal.show(
            "Fecha de estreno: 28 de diciembre - 2024",
            "En proceso",
            TypeAlertModal.error
        )
        // Puedes agregar aquí la lógica adicional que desees para manejar el botón Play
      },
    },
  });
  </script>
  
  <style scoped>
  .teaser-card {
    background: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(5px);
    border-radius: 15px;
    padding: 20px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.3);
  }
  
  .teaser-title {
    font-size: 1rem;
    color: #2a2a2a;
    margin-bottom: 1.5rem;
  }
  
  .video-placeholder {
    background: rgb(22, 22, 22);
    border-radius: 10px;
    height: 200px;
    width: 100%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
  }
  
  .release-date {
    color: #2a2a2a;
    margin-top: 1rem;
    font-size: 0.8rem;
    background-color: rgba(78, 236, 91, 0.479);
    display: inline-block;
    padding: 4px 10px 4px 10px;
    border-radius: 3px;
  }
  </style>
  