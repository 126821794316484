<template>
    <div class="menu-container" :style="{ '--menu-color': color }">
      <!-- Botón del menú -->
      <input
        class="menu-icon"
        type="checkbox"
        id="menu-icon"
        v-model="isMenuOpen"
      />
      <label for="menu-icon"></label>
  
      <!-- Menú (aparece solo cuando isMenuOpen es true) -->
      <div class="nav" :class="{ 'nav-open': isMenuOpen }">
        <ul>
          <li v-for="item in menuItems" :key="item.name">
            <!-- <a href="#">{{ item }}</a> -->
             <router-link :to="item.link" @click="cleanMenu()">{{ item.name }}</router-link>
          </li>
        </ul>
    </div>
    </div>
  </template>
  
  <script lang="ts" setup>
  import { ref, defineProps } from 'vue';
  
  defineProps<{
    color:string
  }>()

  const isMenuOpen = ref(false);
  
      // Elementos del menú (puedes personalizarlos)
  const menuItems = ref([
        {
            name:'Inicio',
            link:'/'
        }, 
        {
            name:'Invitación',
            link:'invitacion'
        }, 
        {
            name:'El gran día',
            link: '/'
        }, 
        {
            name:'Galería',
            link:'#'
        }
    ]);
  const cleanMenu = ():void=>{
    isMenuOpen.value = false;
  }


  </script>
  
  <style scoped>
  
.menu-container{
    position: relative;
}
[type="checkbox"]:checked,
[type="checkbox"]:not(:checked){
  position: absolute;
  left: -9999px;
}
.menu-icon:checked + label,
.menu-icon:not(:checked) + label{
  position: fixed;
  top: 20px;
  right: 15px;
  /* transform: translate(-50%,-50%); */
  display: block;
  width: 30px;
  height: 30px;
  padding: 0;
  margin: 0;
  cursor: pointer;
  z-index: 10;
}
.menu-icon:checked + label:before,
.menu-icon:not(:checked) + label:before{
  position: absolute;
  content: '';
  display: block;
  width: 30px;
  height: 20px;
  z-index: 20;
  top: 0;
  left: 0;
  border-top: 2px solid var(--menu-color, #ececee);
  border-bottom: 2px solid var(--menu-color, #ececee);
  transition: border-width 100ms 1500ms ease, 
              top 100ms 1600ms cubic-bezier(0.23, 1, 0.32, 1),
              height 100ms 1600ms cubic-bezier(0.23, 1, 0.32, 1), 
              background-color 200ms ease,
              transform 200ms cubic-bezier(0.23, 1, 0.32, 1);
}
.menu-icon:checked + label:after,
.menu-icon:not(:checked) + label:after{
  position: absolute;
  content: '';
  display: block;
  width: 22px;
  height: 2px;
  z-index: 20;
  top: 10px;
  right: 4px;
  background-color: var(--menu-color, #ececee);
  margin-top: -1px;
  transition: width 100ms 1750ms ease, 
              right 100ms 1750ms ease,
              margin-top 100ms ease, 
              transform 200ms cubic-bezier(0.23, 1, 0.32, 1);
}
.menu-icon:checked + label:before{
  top: 10px;
  transform: rotate(45deg);
  height: 2px;
  background-color: var(--menu-color, #ececee);
  border-width: 0;
  transition: border-width 100ms 340ms ease, 
              top 100ms 300ms cubic-bezier(0.23, 1, 0.32, 1),
              height 100ms 300ms cubic-bezier(0.23, 1, 0.32, 1), 
              background-color 200ms 500ms ease,
              transform 200ms 1700ms cubic-bezier(0.23, 1, 0.32, 1);
}
.menu-icon:checked + label:after{
  width: 30px;
  margin-top: 0;
  right: 0;
  transform: rotate(-45deg);
  transition: width 100ms ease,
              right 100ms ease,  
              margin-top 100ms 500ms ease, 
              transform 200ms 1700ms cubic-bezier(0.23, 1, 0.32, 1);
  /* transition: 
  width 53ms ease, 
  right 53ms ease,  
  margin-top 53ms 263ms ease, 
  transform 105ms 894ms cubic-bezier(0.23, 1, 0.32, 1); */
}

.nav{
  position: fixed;
  top: 0px;
  right: 0px;
  display: block;
  width: 60px;
  height: 60px;
  padding: 0;
  margin: 0;
  z-index: 9;
  overflow: hidden;
}

.menu-icon:checked ~ .nav {
  position: fixed;
  top: 0px;
  right: 0px;
  /* transform: translate(50%, -50%); */
  width: 100%;
  height: 100vh;
  /* transition: top 350ms 700ms cubic-bezier(0.23, 1, 0.32, 1),  
              right 350ms 700ms cubic-bezier(0.23, 1, 0.32, 1),
              transform 250ms 700ms ease,
              width 750ms 1000ms cubic-bezier(0.23, 1, 0.32, 1),
              height 750ms 1000ms cubic-bezier(0.23, 1, 0.32, 1); */
  transition: 
    top 200ms 400ms cubic-bezier(0.23, 1, 0.32, 1),  
    right 200ms 400ms cubic-bezier(0.23, 1, 0.32, 1),
    transform 150ms 400ms ease,
    width 300ms 500ms cubic-bezier(0.23, 1, 0.32, 1),
    height 300ms 500ms cubic-bezier(0.23, 1, 0.32, 1);
 
  background-color: #3537468e;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(10px); 
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.nav ul{
  position: relative;
  top: 50%;
  left: 0;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  z-index: 6;
  text-align: center;
  transform: translateY(-50%);
  list-style: none;
}
.nav ul li{
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  margin: 10px 0;
  text-align: center;
  list-style: none;
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
  transform: translateY(30px);
  transition: all 250ms linear;
}
.nav ul li:nth-child(1){
  transition-delay: 200ms;
}
.nav ul li:nth-child(2){
  transition-delay: 150ms;
}
.nav ul li:nth-child(3){
  transition-delay: 100ms;
}
.nav ul li:nth-child(4){
  transition-delay: 50ms;
}
.nav ul li a{
  /* font-family: 'Montserrat', sans-serif; */
  font-size: 9vh;
  text-transform: uppercase;
  line-height: 1.2;
  font-weight: 800;
  display: inline-block;
  position: relative;
  color: #ffffff;
  transition: all 250ms linear;
}
.nav ul li a:hover{
  text-decoration: none;
  color: #ffeba7;
}
.nav ul li a:after{
  display: block;
  position: absolute;
  top: 50%;
  content: '';
  height: 2vh;
  margin-top: -1vh;
  width: 0;
  left: 0;
  background-color: #353746;
  opacity: 0.8;
  transition: width 250ms linear;
}
.nav ul li a:hover:after{
  width: 100%;
}


.menu-icon:checked ~ .nav  ul li {
  pointer-events: auto;
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
  transition: opacity 350ms ease,
              transform 250ms ease;
}
.menu-icon:checked ~ .nav ul li:nth-child(1){
  transition-delay: 1400ms;
}
.menu-icon:checked ~ .nav ul li:nth-child(2){
  transition-delay: 1480ms;
}
.menu-icon:checked ~ .nav ul li:nth-child(3){
  transition-delay: 1560ms;
}
.menu-icon:checked ~ .nav ul li:nth-child(4){
  transition-delay: 1640ms;
}


@media screen and (max-width: 991px) {
  .menu-icon:checked + label,
  .menu-icon:not(:checked) + label{
    right: 45px;
  }
  .nav{
    right: 30px;
  }
  .nav ul li a{
    font-size: 8vh;
  }
}

  </style>
  