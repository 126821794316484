import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "video-placeholder d-flex justify-center align-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, {
    class: "teaser-card",
    elevation: "12"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_title, { class: "justify-center" }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode(" Nuestro Camino al \"Sí, Acepto\" ")
        ])),
        _: 1
      }),
      _createVNode(_component_v_card_text, { class: "text-center" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_v_btn, {
              icon: "",
              color: "white",
              elevation: "0",
              onClick: _ctx.handlePlay
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_icon, { size: "48" }, {
                  default: _withCtx(() => _cache[1] || (_cache[1] = [
                    _createTextVNode("mdi-play-circle")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _cache[2] || (_cache[2] = _createElementVNode("p", { class: "release-date" }, "✨ Estreno: 28 de diciembre - 2024 ✨", -1))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}