<template>
    <div class="pa-4 text-center">
      <v-dialog
        v-model="dialog"
        max-width="600"
      >
        <template v-slot:activator="{ props: activatorProps }">
          <v-btn
            class="text-none font-weight-bold"
            text="Confirmar asistencia aquí"
            variant="tonal"
            v-bind="activatorProps"
          ></v-btn>
        </template>
  
        <v-card
          class="font-weight-bold"
          title="Agradecemos que confirmes tu asistencia"
        >
          <v-card-text>
            <v-row dense>
              <v-col
                cols="12"
                md="6"
                sm="6"
              >
                <v-text-field
                  label="Nombres"
                  v-model="nombres"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
  
              <v-col
                cols="12"
                md="6"
                sm="6"
              >
                <v-text-field
                  label="Apellidos"
                  v-model="apellidos"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
  
              <v-col
                cols="12"
                sm="6"
              >
                <v-radio-group class="font-weight-bold" v-model="invitado" :rules="[rules.required]">
                  <v-radio label="Invitado del novio" value="novio"></v-radio>
                  <v-radio label="Invitado de la novia" value="novia"></v-radio>
                  <v-radio label="Me invité solo" value="alone"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-card-text>
  
          <v-divider></v-divider>
  
          <v-card-actions>
            <v-spacer></v-spacer>
  
            <v-btn
              class="font-weight-bold"
              text="Cancelar"
              variant="plain"
              @click="dialog = false"
            ></v-btn>
  
            <v-btn
              class="font-weight-bold"
              color="green"
              text="Guardar datos"
              variant="tonal"
              @click="agregarConfirmacion()"
              :disabled="isLoading || !isValidForm"
            ></v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </template>
  
  <script lang="ts" setup>
  import { ref, getCurrentInstance, computed } from 'vue';
  import { setDoc, doc, collection } from 'firebase/firestore';
  import InvoiceModal from '@/helpers/InvoiceModal'

  const dialog = ref(false);
  const isLoading = ref(false);
  
  const instance = getCurrentInstance();
  const db = instance?.appContext.config.globalProperties.$db;
  const nombres = ref('');
  const apellidos = ref('');
  const invitado = ref('');
  
  const rules = {
    required: (value: string) => !!value || 'Este campo es obligatorio',
  };
  
  const isValidForm = computed(() => {
    return nombres.value !== '' && apellidos.value !== '' && invitado.value !== '';
  });
  
  const agregarConfirmacion = async () => {
    isLoading.value = true;
    if (db) {
      try {
        await setDoc(doc(collection(db, 'confirmaciones')), {
          nombres: nombres.value.toLocaleUpperCase(),
          apellidos: apellidos.value.toLocaleUpperCase(),
          invitado: invitado.value.toLocaleUpperCase()
        });
        // console.log('Confirmación agregada con éxito!');
        InvoiceModal.show(nombres.value.toLocaleUpperCase() + ' ' +apellidos.value.toLocaleUpperCase())
        nombres.value = '';
        apellidos.value = '';
        invitado.value = '';
        dialog.value = false;
        isLoading.value = false;
      } catch (error) {
        console.error('Error al agregar confirmación:', error);
        dialog.value = false;
        isLoading.value = false;
      }
    } else {
      console.error('No se pudo obtener la instancia de Firestore');
      dialog.value = false;
      isLoading.value = false;
    }
  };
  </script>