import { useInvoiceModalStore } from '@/store/storeHelper'; // Importa el store de Pinia
import { IInvoiceModal } from '@/interfaces/store/storeHelper.interface';

export default {
  show(text: string) {
    const alertModalStore = useInvoiceModalStore();
    const payload: IInvoiceModal = {
      text,
      show: true,
    };
    alertModalStore.showAlert(payload);
  },

  hide() {
    const alertModalStore = useInvoiceModalStore();

    const payload: IInvoiceModal = {
      text: '',
      show: false,
    };
    alertModalStore.hideAlert(payload);
  },
};
