<template>
    <v-card
        class="teaser-card"
        elevation="12"
      >
        <slot></slot>
      </v-card>
  </template>
  
  <script lang="ts">
  import { defineComponent } from "vue";
  
  export default defineComponent({
    name: "CardGlass",
    methods: {
    },
  });
  </script>
  
  <style scoped>
  .teaser-card {
    background: rgba(255, 255, 255, 0.356);
    backdrop-filter: blur(5px);
    border-radius: 15px;
    padding: 20px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.3);
  }
  
  </style>
  