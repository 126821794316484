<template>
    <v-row justify="center">
      <v-dialog
        v-model="dialog"
        persistent
        max-width="500"
        light
      >
        <v-card class="pa-3 pt-8">
          <div id="canvas" width="300px" height="300px"></div>
          <v-card-title class="text-h5 text-center">
            ¡GRACIAS POR CONFIRMAR!
          </v-card-title>
          <v-card-text class="text-center">
            <p>Tu confirmación se guardó con éxito.</p>
            <p>Te estaremos esperando {{text}}</p>
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn
              color="green darken-1"
              variant="text"
              @click="save()"
              :disabled="isSaving"
              :loading="isSaving"
            >
              ok
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </template>
  
  <script setup >
    import { ref, watch } from 'vue'
    import { useInvoiceModalStore } from '@/store/storeHelper';
    import InvoiceModal from '@/helpers/InvoiceModal';
    import JSConfetti from 'js-confetti'

    // const jsConfetti = new JSConfetti()

    const canvas = document.getElementById('canvas')

    const jsConfetti = new JSConfetti({ canvas })
    
    const store = useInvoiceModalStore()
    const dialog = ref(false)
    const text = ref('')
    const isSaving = ref(false)
    
    watch(() => store.alertModal,(newVal) => {
          dialog.value = newVal.show
          text.value = newVal.text
          if (newVal.show) { 
          jsConfetti.addConfetti({
            particleCount: 100,
            spread: 100,
            colors: ['#FF69B4', '#FFC67D', '#8BC34A'],
          })
          setTimeout(() => {
            jsConfetti.addConfetti({
              particleCount: 200,
              spread: 150,
              colors: ['#FF69B4', '#FFC67D', '#8BC34A'],
            })
          }, 2000) // Retraso de 2 segundos
          setTimeout(() => {
            jsConfetti.addConfetti({
              particleCount: 200,
              spread: 250,
              colors: ['#FF69B4', '#FFC67D', '#8BC34A'],
            })
          }, 4000) // Retraso de 4 segundos
        }
      },
      { immediate: true }
      )
  
    const save = async () => {
      isSaving.value = true;
      dialog.value = false
      isSaving.value = false;
      InvoiceModal.hide()
    }
  </script>
  
  <style scoped>
  #canvas {
    position: fixed;
    z-index: 9999999999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
</style>
  