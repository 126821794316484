// store/alertModal.ts
import { defineStore } from 'pinia';
import { IAlertModal, IInvoiceModal } from '@/interfaces/store/storeHelper.interface';

export const useAlertModalStore = defineStore('alertModal', {
  state: () => ({
    alertModal: {
      text: '',
      title: '',
      type: undefined,
      show: false,
    } as IAlertModal,
  }),
  actions: {
    showAlert(payload: IAlertModal) {
      this.alertModal = payload;
    },
    hideAlert(payload: IAlertModal) {
      this.alertModal = payload;
    },
  },
});

export const useInvoiceModalStore = defineStore('invoiceModal', {
  state: () => ({
    alertModal: {
      text: '',
      show: false,
    } as IInvoiceModal,
  }),
  actions: {
    showAlert(payload: IInvoiceModal) {
      this.alertModal = payload;
    },
    hideAlert(payload: IInvoiceModal) {
      this.alertModal = payload;
    },
  },
});
