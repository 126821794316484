// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import { aliases, mdi } from 'vuetify/iconsets/mdi'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

// Crea la instancia de Vuetify con la configuración de temas
export default createVuetify({
  theme: {
    defaultTheme: 'light', // Por defecto, inicia en modo claro
    themes: {
      light: {
        dark: false,
        colors: {
          primary: '#1976D2',
          secondary: '#424242',
          accent: '#82B1FF',
          // Otros colores personalizados
        },
      },
      dark: {
        dark: true,
        colors: {
          primary: '#1E88E5',
          secondary: '#424242',
          accent: '#FF4081',
          // Otros colores personalizados
        },
      },
    },
  },
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    },
  },
  components,
  directives,
})