<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="500"
      light
    >
      <v-card class="pa-3 pt-8">
        <div class="text-center">
          <v-icon color="green darken-1" v-if="type" size="x-large">{{ type }}</v-icon>
        </div>
        <v-card-title class="text-h5 text-center">
          {{ title }}
        </v-card-title>
        <v-card-text class="text-center">
          {{ text }}
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            color="green darken-1"
            variant="text"
            @click="close"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script setup lang="ts">
  import { ref, watch } from 'vue'
  import { useAlertModalStore } from '@/store/storeHelper';
  import AlertModalUtil from '@/helpers/AlertModal'
  import { TypeAlertModal } from '@/enums/alertModal.enum'

  const store = useAlertModalStore()

  const dialog = ref(false)
  const text = ref('')
  const title = ref('')
  const type = ref<TypeAlertModal | null>(null)
  
  watch(() => store.alertModal,(newVal) => {
        dialog.value = newVal.show
        text.value = newVal.text
        title.value = newVal.title
        type.value = newVal.type
    },
    { immediate: true }
    )

  const close = () => {
    dialog.value = false
    AlertModalUtil.hide()
  }
</script>

<style scoped></style>
