<template>
    <v-timeline align="start">
        <v-timeline-item  
        icon="mdi-heart"
        icon-color="red"
        dot-color="white"
        class="tl-item-text">
            <template v-slot:opposite>
                <p style="text-align: end;">
                    ¡sí quiero! bajo la mirada de dios.
                </p>
            </template>
            <div>
                <p> 8:20 A.M.</p>
                
            </div>
        </v-timeline-item>

        <v-timeline-item  
        icon="mdi-heart"
        icon-color="red"
        dot-color="white"
        class="tl-item-text">
            <template v-slot:opposite>
                <p>
                    ¡nos vamos al local, súbete ya!
                </p>
            </template>
            <div >
    
                <p style="text-align: right;">
                10:00 A.M.
                </p>
            </div>
        </v-timeline-item>

        <v-timeline-item  
        icon="mdi-heart"
        icon-color="red"
        dot-color="white"
        class="tl-item-text">
            <template v-slot:opposite>
                <p>
                    ¡Bienvenidos!
                    
                </p>
            </template>
            <p>
                11:30 A.M.
            </p>
        </v-timeline-item>

        <v-timeline-item  
        icon="mdi-heart"
        icon-color="red"
        dot-color="white"
        class="tl-item-text">
            <template v-slot:opposite>
                <p>
                    
                    ¡sí quiero! firmado, sellado y con todos
los papeles en regla.
                </p>
            </template>
            <p style="text-align: end;">
                12:00 M.
            </p>
        </v-timeline-item>

        <v-timeline-item  
        icon="mdi-heart"
        icon-color="red"
        dot-color="white"
        class="tl-item-text">
            <template v-slot:opposite>
                <p style="text-align: end;">
                    nuestro primer baile, 
quién diría que nos casamos.
                </p>
            </template>
            <p>
                01:30 P.M.
            </p>
        </v-timeline-item>

        <v-timeline-item  
        icon="mdi-heart"
        icon-color="red"
        dot-color="white"
        class="tl-item-text">
            <template v-slot:opposite>
                <p>
                    brindis ¡que vivan los novios!
                    
                </p>
            </template>
            <p style="text-align: end;">
                02:30 P.M.
            </p>
        </v-timeline-item>
        <v-timeline-item  
        icon="mdi-heart"
        icon-color="red"
        dot-color="white"
        class="tl-item-text">
            <template v-slot:opposite>
                <p style="text-align: end;">
                    BUEN PROVECHO.
                    
                </p>
            </template>
            <p >
                03:00 P.M.
            </p>
        </v-timeline-item>
        <v-timeline-item  
        icon="mdi-heart"
        icon-color="red"
        dot-color="white"
        class="tl-item-text">
            <template v-slot:opposite>
                <p>
                    QUE COMIENCE LA FIESTA.
                </p>
            </template>
            <p style="text-align: end;">
                04:00 P.M.
            </p>
        </v-timeline-item>
        <v-timeline-item  
        icon="mdi-heart"
        icon-color="red"
        dot-color="white"
        class="tl-item-text">
            <template v-slot:opposite>
                <p>
                    nos vamos a casa.
                    
                </p>
            </template>
            <p>
                01:00 A.M.
            </p>
        </v-timeline-item>
    </v-timeline>
</template>
<script setup lang="ts">
</script>
<style scoped>
.tl-item-text{
    font-size: 0.8rem;
}
</style>