<template>
    <v-card class="card-clock-down glass-effect" elevation="12">
      <v-card-title class="justify-center">
        💍 ¡sólo faltan! 💒
      </v-card-title>
      <v-card-text>
        <div v-if="remainingTime > 0" class="countdown-timer">
          <div class="time-unit" v-for="(value, label) in timeValues" :key="label">
            <transition-group name="flip" tag="div" class="value-container">
              <span class="value" :key="value">{{ value }}</span>
            </transition-group>
            <span class="label">{{ label }}</span>
          </div>
        </div>
        <div v-else class="final-message">
          {{ endMessage }}
        </div>
      </v-card-text>
    </v-card>
  </template>
  
  <script lang="ts" setup>
  import { ref, onMounted, onUnmounted, computed } from 'vue';
  
  // **Configuración inicial**
  const endDate = ref<Date>(new Date('2024-12-28T00:00:00')); // Fecha final configurable
  const endMessage = ref<string>('¡Hoy es el gran día! 💕');
  
  // Estado reactivo para el tiempo restante
  const now = ref<Date>(new Date());
  const remainingTime = computed<number>(() =>
    Math.max(0, endDate.value.getTime() - now.value.getTime())
  );
  
  // Derivar días, horas, minutos y segundos
  const days = computed<number>(() =>
    Math.floor(remainingTime.value / (1000 * 60 * 60 * 24))
  );
  const hours = computed<number>(() =>
    Math.floor((remainingTime.value % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
  );
  const minutes = computed<number>(() =>
    Math.floor((remainingTime.value % (1000 * 60 * 60)) / (1000 * 60))
  );
  const seconds = computed<number>(() => Math.floor((remainingTime.value % (1000 * 60)) / 1000));
  
  // Objeto para iterar fácilmente sobre los valores
  const timeValues = computed<Record<string, number>>(() => ({
    Días: days.value,
    Horas: hours.value,
    Minutos: minutes.value,
    Segundos: seconds.value,
  }));
  
  // **Actualizar el tiempo restante cada segundo**
  let interval: number;
  onMounted(() => {
    interval = setInterval(() => {
      now.value = new Date();
    }, 1000);
  });
  
  // **Limpiar el intervalo cuando el componente se desmonte**
  onUnmounted(() => {
    clearInterval(interval);
  });
  </script>
  
  <style scoped>
  .card-clock-down {
    margin: auto;
    padding: 20px;
    text-align: center;
    color: #2e2e2e;
    border-radius: 15px;
    background: rgba(255, 255, 255, 0.1); /* Glass effect */
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.18);
  }
  
  .countdown-timer {
    display: flex;
    justify-content: space-around;
  }
  
  .time-unit {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background-color: red; */
  }
  
  .value-container {
    position: relative;
    overflow: hidden;
    /* background-color: #ff9aae; */
    width: 2.2rem;
    height: 3rem;
  }
  
  .value {
    font-size: 2rem;
    font-weight: bold;
    display: inline-block;
    transition: transform 0.3s ease-in-out;
  }
  
  .label {
    font-size: 0.875rem;
  }
  
  /* Animación Flip */
  .flip-enter-active,
  .flip-leave-active {
    transition: transform 0.3s ease-in-out;
  }
  
  .flip-enter-from {
    transform: translateY(100%);
  }
  
  .flip-enter-to {
    transform: translateY(0%);
  }
  
  .flip-leave-from {
    transform: translateY(0%);
  }
  
  .flip-leave-to {
    transform: translateY(-100%);
  }
  
  .final-message {
    font-size: 1.5rem;
    font-weight: bold;
    color: #ff9aae;
  }
  </style>
  