import { TypeAlertModal } from '@/enums/alertModal.enum';
import { useAlertModalStore } from '@/store/storeHelper'; // Importa el store de Pinia
import { IAlertModal } from '@/interfaces/store/storeHelper.interface';

export default {
  show(text: string, title: string, type?: TypeAlertModal) {
    const alertModalStore = useAlertModalStore();
    const payload: IAlertModal = {
      text,
      title,
      type,
      show: true,
    };
    alertModalStore.showAlert(payload);
  },

  hide() {
    const alertModalStore = useAlertModalStore();

    const payload: IAlertModal = {
      text: '',
      title: '',
      type: undefined,
      show: false,
    };
    alertModalStore.hideAlert(payload);
  },
};
