<template>
    <div>
        <p @click="dialog = true" class="titlin-text" >
            <slot></slot>
      </p>
      <v-dialog
        v-model="dialog"
        width="auto"
      >
        <v-card
          max-width="90vw"
          min-height="400px"
          prepend-icon="mdi-map-marker"
          :title="title"
        >
            <div>
                <iframe 
                    :src="urlmap" 
                    width="100%" 
                    height="400px"
                    style="border:0;" 
                    loading="lazy" 
                    referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>

          <template v-slot:actions>
            <v-btn
              class="font-weight-bold"
              color="green"
              @click="dialog = false"
            > OK</v-btn>
          </template>
        </v-card>
      </v-dialog>
    </div>
  </template>
  <script lang="ts" setup>
    import { ref, defineProps } from 'vue';
  
    defineProps<{
        urlmap:string,
        title:string
    }>()
    const dialog =  ref(false);
  </script>
  <style scoped>
    .titlin-text{
        font-size: 0.7rem;
        cursor: pointer;
        transition: all 0.5s;
    }
    .titlin-text:hover{
        color: rgb(48, 75, 156);
        
    }
  </style>